import {
    validationDefault,
    validationDefaultNew,
} from '../components/utilities/rulesUtilities';
import genericOptions from './defaultConfigRole/genericOptions';

const Rules = {
    role_7aa8d689df234eeaba954a9e9d1a84d5: {
        //Administrador
        home: '/balanceSheet',
        executive: false,
        views: {
            create: {
                titleRequestCredit: 'Recargas',
                permisions: ['listRequestCredit'],

                components: [
                    {
                        name: 'Recargas',
                        component: ['FormRecharge'],
                    },
                ],
            },
            profile: {
                fields: [
                    {
                        name: 'identification_number',
                        label: 'Número de identificación',
                        disabled: false,
                        type: 'integer',
                        col: 6,
                        validation: validationDefaultNew(
                            [
                                { type: 'required', value: true },
                                { type: 'maxLength', value: 12 },
                                { type: 'minLength', value: 6 },
                            ],
                            'número de identificación'
                        ),
                    },
                    {
                        name: 'first_name',
                        label: 'Nombres',
                        disabled: false,
                        type: 'string',
                        col: 6,
                        validation: validationDefaultNew(
                            [{ type: 'required', value: true }],
                            'nombres'
                        ),
                    },
                    {
                        name: 'last_name',
                        label: 'Apellidos',
                        disabled: false,
                        type: 'string',
                        col: 6,
                        validation: validationDefaultNew(
                            [{ type: 'required', value: true }],
                            'apellidos'
                        ),
                    },
                    {
                        name: 'phone_number',
                        label: 'Número telefónico',
                        disabled: false,
                        type: 'celular',
                        col: 6,
                        validation: validationDefaultNew(
                            [{ type: 'required', value: true }],
                            'Número telefónico'
                        ),
                    },
                ],
            },
        },
        components: {
            formRecharge: {
                permisions: [],
                tittle: 'recargas',
                fields: [
                    {
                        name: 'usuario',
                        disabled: false,
                        type: 'select',
                        col: 4,
                        url: '/globals/users/?is_active=true',
                        notSearchGlobals: true,
                        validation: validationDefault(['required'], 'usuario'),
                    },
                    {
                        name: 'tipo_recarga',
                        disabled: false,
                        type: 'select',
                        col: 3,
                        showTable: true,
                        options: [],
                        url: '/globals/tipo_recarga/',
                        validation: validationDefault(
                            ['required'],
                            'tipo recarga'
                        ),
                    },
                    {
                        name: 'tipo_corresponsal_bancario',
                        disabled: false,
                        type: 'select',
                        col: 3,
                        url: '/globals/tipo_corresponsal/?is_active=true',
                        options: [],
                        notSearchGlobals: true,
                    },
                    {
                        name: 'valor',
                        disabled: false,
                        type: 'price',
                        col: 2,
                        validation: validationDefault(['required'], 'valor'),
                    },
                ],
            },
            formUser: {
                permisions: [],
                tittle: 'user',
                fields: [
                    {
                        name: 'first_name',
                        label: 'Nombre',
                        disabled: false,
                        type: 'string-letter',
                        col: 4,
                        showTable: true,
                        validation: validationDefault(['required'], 'nombre'),
                    },
                    {
                        name: 'email',
                        label: 'Correo electrónico',
                        disabled: false,
                        type: 'email',
                        col: 4,
                        showTable: true,
                        validation: validationDefault(
                            ['required'],
                            'correo electrónico'
                        ),
                    },
                    {
                        name: 'password',
                        label: 'Contraseña',
                        disabled: false,
                        type: 'password-eye',
                        col: 4,
                        showTable: true,
                        // validation: validationDefault(
                        //     ['required'],
                        //     'contraseña'
                        // ),
                    },
                    // {
                    //     name: 'identification_number',
                    //     label: 'Número de identificación',
                    //     disabled: false,
                    //     type: 'integer',
                    //     col: 3,
                    //     validation: validationDefault(
                    //         ['required'],
                    //         'Número de identificación'
                    //     ),
                    // },
                    {
                        name: 'groups',
                        label: 'Rol',
                        disabled: false,
                        type: 'select',
                        col: 4,
                        url: 'globals/roles/',
                        notSearchGlobals: true,
                        validation: validationDefault(['required'], 'rol'),
                    },
                    {
                        name: 'tipo_usuario',
                        disabled: false,
                        type: 'select-multiple',
                        col: 4,
                        showTable: true,
                        options: [],
                        url: '/globals/tipo_usuario/',
                        validation: validationDefault(
                            ['required'],
                            'tipo usuario'
                        ),
                    },
                    {
                        name: 'tipo_corresponsal_bancario',
                        disabled: false,
                        type: 'select',
                        options: [],
                        col: 4,
                        url: '/globals/tipo_corresponsal/?is_active=true',
                        notSearchGlobals: true,
                    },
                ],
            },
            formAccountType: {
                permisions: [],
                tittle: 'tipo_cuenta',
                fields: [
                    {
                        name: 'nombre',
                        label: 'Nombre',
                        disabled: false,
                        type: 'string',
                        col: 5,
                        showTable: true,
                        validation: validationDefault(['required'], 'Nombre'),
                    },
                    {
                        name: 'estado',
                        disabled: false,
                        type: 'select',
                        col: 4,
                        showTable: true,
                        options: genericOptions.estado,
                        validation: validationDefault(['required'], 'Estado'),
                    },
                    {
                        name: 'valor',
                        disabled: false,
                        type: 'price',
                        col: 3,
                        validation: validationDefault(['required'], 'valor'),
                    },
                    {
                        name: 'cantidad_pantallas',
                        label: 'Cantidad ventas',
                        disabled: false,
                        type: 'integer',
                        col: 3,
                        validation: validationDefault(
                            ['required'],
                            'Cantidad pantallas'
                        ),
                    },
                    {
                        name: 'perfil',
                        label: 'Mostrar perfil',
                        disabled: false,
                        type: 'select',
                        col: 3,
                        showTable: true,
                        options: genericOptions.si_no,
                        validation: validationDefault(
                            ['required'],
                            'Mostrar perfil'
                        ),
                    },
                    {
                        name: 'perfil_especial',
                        label: '2 perfiles en 1',
                        disabled: false,
                        type: 'select',
                        col: 3,
                        showTable: true,
                        options: genericOptions.si_no,
                        validation: validationDefault(
                            ['required'],
                            '2 perfiles en 1'
                        ),
                    },
                    {
                        name: 'cantidad_dispositivos',
                        disabled: false,
                        type: 'integer',
                        col: 3,
                        validation: validationDefault(
                            ['required'],
                            'Cantidad dispositivos'
                        ),
                    },
                    {
                        name: 'mostrar_campos',
                        disabled: false,
                        type: 'integer',
                        col: 3,
                        validation: validationDefault(
                            ['required'],
                            'Mostrar campos'
                        ),
                    },
                    {
                        name: 'groups',
                        label: 'Rol',
                        disabled: false,
                        type: 'select-multiple',
                        col: 4,
                        url: 'globals/roles/',
                        notSearchGlobals: true,
                        validation: validationDefault(['required'], 'rol'),
                    },
                    {
                        name: 'icon',
                        label: 'Imagen',
                        disabled: false,
                        type: 'file',
                        col: 5,
                        // validation: validationDefault(['required'], 'Imagen'),
                    },
                    {
                        name: 'orden_visualizacion',
                        disabled: false,
                        type: 'integer',
                        col: 3,
                    },
                ],
            },
            formAccount: {
                permisions: [],
                tittle: 'tipo_cuenta',
                fields: [
                    {
                        name: 'email',
                        visible: true,
                        disabled: false,
                        type: 'email',
                        col: 6,
                        validation: validationDefaultNew(
                            [
                                {
                                    type: 'minLength',
                                    value: 5,
                                },
                                {
                                    type: 'required',
                                    value: true,
                                },
                                {
                                    type: 'pattern',
                                    value: /[a-zA-Z0-9!#$%&'*_+-]([.]?[a-zA-Z0-9!#$%&'*_+-])+@[a-zA-Z0-9]([^@&%$/()=?¿!.,:;]|d)+[a-zA-Z0-9][.][a-zA-Z]{2,4}([.][a-zA-Z]{2})?/,
                                },
                            ],
                            'correo electrónico es obligatorio'
                        ),
                    },
                    {
                        name: 'contrasena',
                        label: 'Contraseña',
                        disabled: false,
                        type: 'password-eye',
                        col: 6,
                        showTable: true,
                        validation: validationDefault(
                            ['required'],
                            'contraseña'
                        ),
                    },
                    {
                        name: 'tipo_cuenta',
                        disabled: false,
                        type: 'select',
                        col: 6,
                        url: 'globals/tipo_cuenta/',
                        notSearchGlobals: true,
                        validation: validationDefault(
                            ['required'],
                            'Tipo cuenta'
                        ),
                    },
                    {
                        name: 'estado',
                        disabled: false,
                        type: 'select',
                        col: 6,
                        showTable: true,
                        options: genericOptions.estado,
                        validation: validationDefault(['required'], 'Estado'),
                    },
                    {
                        name: 'cantidad_uso_cuenta',
                        label: 'Veces usada',
                        disabled: false,
                        type: 'hidden',
                        col: 6,
                        defaultValue: 0,
                        value: 0,
                    },
                    {
                        name: 'costo',
                        disabled: false,
                        type: 'price',
                        col: 6,
                        validation: validationDefault(['required'], 'costo'),
                    },
                ],
            },
            reportsGeneral: {
                fields: [
                    {
                        name: 'tipo_reporte',
                        disabled: false,
                        type: 'select',
                        col: 4,
                        options: [
                            { value: '0', label: 'CONTENIDO DIGITAL' },
                            { value: '1', label: 'CONSOLIDADO CARTERA' },
                            { value: '2', label: 'TRAMITES' },
                        ],

                        validation: {
                            required: {
                                value: true,
                            },
                        },
                    },
                    {
                        name: 'fecha_desde',
                        label: 'Fecha desde',
                        disabled: false,
                        type: 'date',
                        col: 4,
                        validation: {
                            required: {
                                value: true,
                            },
                        },
                    },
                    {
                        name: 'fecha_hasta',
                        label: 'Fecha hasta',
                        disabled: false,
                        type: 'date',
                        col: 4,
                        validation: {
                            required: {
                                value: true,
                                message: 'Fecha desde',
                            },
                        },
                    },
                    {
                        name: 'punto_de_venta',
                        disabled: false,
                        type: 'select',
                        col: 4,
                        url: '/globals/users/?is_active=true',
                        notSearchGlobals: true,
                    },
                    {
                        name: 'clasificacion_tramite',
                        disabled: false,
                        type: 'select',
                        col: 4,
                        showTable: true,
                        options: genericOptions.clasificacion_tramite,
                    },
                ],
            },
            formBankingCorrespondent: {
                permisions: [],
                tittle: 'corresponsal_bancario',
                fields: [
                    {
                        name: 'tipo_corresponsal_bancario',
                        disabled: false,
                        type: 'select',
                        options: [],
                        col: 4,
                        url: '/globals/tipo_corresponsal/?is_active=true',
                        notSearchGlobals: true,
                        validation: validationDefault(
                            ['required'],
                            'tipo corresponsal bancario'
                        ),
                    },
                    {
                        name: 'tipo',
                        label: 'Tipo accción',
                        disabled: false,
                        type: 'select',
                        col: 4,
                        showTable: true,
                        options: genericOptions.tipo_corresponsal,
                        validation: validationDefault(
                            ['required'],
                            'Tipo corresponsal'
                        ),
                    },
                    {
                        name: 'estado',
                        disabled: false,
                        type: 'select',
                        col: 4,
                        showTable: true,
                        options: genericOptions.estado,
                        validation: validationDefault(['required'], 'Estado'),
                    },
                    {
                        name: 'longitud_token',
                        label: 'Longitud token/cédula',
                        disabled: false,
                        type: 'integer',
                        col: 4,
                    },
                    {
                        name: 'valor_minimo_operacion',
                        label: 'Valor tope minimo',
                        disabled: false,
                        type: 'price',
                        col: 4,
                        validation: validationDefault(
                            ['required'],
                            'Valor tope minimo'
                        ),
                    },
                    {
                        name: 'valor_maximo_operacion',
                        label: 'Valor tope maximo',
                        disabled: false,
                        type: 'price',
                        col: 4,
                        validation: validationDefault(
                            ['required'],
                            'Valor tope maximo'
                        ),
                    },
                    {
                        name: 'imagen',
                        label: 'Imagen',
                        disabled: false,
                        type: 'file',
                        col: 6,
                        // validation: validationDefault(['required'], 'Imagen'),
                    },
                    {
                        name: 'orden_visualizacion',
                        disabled: false,
                        type: 'integer',
                        col: 3,
                    },
                ],
            },
            formTypeBankingCorrespondent: {
                permisions: [],
                tittle: 'tipo_corresponsal_bancario',
                fields: [
                    {
                        name: 'nombre',
                        label: 'Nombre',
                        disabled: false,
                        type: 'string',
                        col: 3,
                        showTable: true,
                        validation: validationDefault(['required'], 'Nombre'),
                    },
                    {
                        name: 'estado',
                        disabled: false,
                        type: 'select',
                        col: 3,
                        showTable: true,
                        options: genericOptions.estado,
                        validation: validationDefault(['required'], 'Estado'),
                    },
                    {
                        name: 'imagen',
                        label: 'Imagen',
                        disabled: false,
                        type: 'file',
                        col: 6,
                        // validation: validationDefault(['required'], 'Imagen'),
                    },
                ],
            },
            formOperationBankingCorrespondent: {
                permisions: [],
                tittle: 'operacion_corresponsal_bancario',
                fields: {
                    generico: [
                        {
                            name: 'numero_producto',
                            label: 'Número producto',
                            disabled: false,
                            type: 'integer',
                            col: 12,
                            showTable: true,
                            validation: validationDefault(
                                ['required'],
                                'Número producto'
                            ),
                        },
                        {
                            name: 'token',
                            label: 'Token',
                            disabled: false,
                            type: 'string',
                            col: 12,
                            validation: validationDefaultNew(
                                [{ type: 'required', value: true }],
                                'Token'
                            ),
                        },
                        {
                            name: 'valor_operacion',
                            label: 'Valor operación',
                            disabled: false,
                            type: 'price',
                            col: 12,
                            validation: validationDefault(
                                ['required'],
                                'Valor operación'
                            ),
                        },
                        {
                            name: 'valor_efectivo',
                            label: 'Valor pago en efectivo',
                            disabled: false,
                            type: 'price',
                            col: 12,
                            validation: validationDefaultNew(
                                [{ type: 'required', value: true }],
                                'Valor pago en efectivo'
                            ),
                        },
                        {
                            name: 'valor_devuelta',
                            label: 'Valor devuelta',
                            disabled: true,
                            type: 'price',
                            col: 12,
                        },
                        {
                            name: 'corresponsal_bancario',
                            disabled: false,
                            type: 'hidden',
                            col: 12,
                            value: '',
                        },
                    ],
                    daviplata: [
                        {
                            name: 'tipo_documento',
                            disabled: false,
                            type: 'select',
                            col: 12,
                            showTable: true,
                            options: genericOptions.tipo_documento,
                            validation: validationDefault(
                                ['required'],
                                'Tipo documento'
                            ),
                        },
                        {
                            name: 'numero_documento',
                            label: 'Número documento',
                            disabled: false,
                            type: 'integer',
                            col: 12,
                            showTable: true,
                            validation: validationDefaultNew(
                                [
                                    { type: 'required', value: true },
                                    { type: 'maxLength', value: 14 },
                                    { type: 'minLength', value: 6 },
                                ],
                                'número de documento'
                            ),
                        },
                        {
                            name: 'numero_producto',
                            label: 'Número Producto',
                            disabled: false,
                            type: 'integer',
                            col: 12,
                            showTable: true,
                            validation: validationDefaultNew(
                                [
                                    { type: 'required', value: true },
                                    { type: 'maxLength', value: 10 },
                                    { type: 'minLength', value: 10 },
                                ],
                                'número de documento'
                            ),
                        },
                        {
                            name: 'token',
                            label: 'Token',
                            disabled: false,
                            type: 'string',
                            col: 12,
                            validation: validationDefaultNew(
                                [{ type: 'required', value: true }],
                                'Token'
                            ),
                        },
                        {
                            name: 'valor_operacion',
                            label: 'Valor operación',
                            disabled: false,
                            type: 'price',
                            col: 12,
                            validation: validationDefault(
                                ['required'],
                                'Valor operación'
                            ),
                        },
                        {
                            name: 'valor_efectivo',
                            label: 'Valor pago en efectivo',
                            disabled: false,
                            type: 'price',
                            col: 12,
                            validation: validationDefaultNew(
                                [{ type: 'required', value: true }],
                                'Valor pago en efectivo'
                            ),
                        },
                        {
                            name: 'valor_devuelta',
                            label: 'Valor devuelta',
                            disabled: true,
                            type: 'price',
                            col: 12,
                        },
                        {
                            name: 'corresponsal_bancario',
                            disabled: false,
                            type: 'hidden',
                            col: 12,
                            value: '',
                        },
                    ],
                    grupo_aval: [
                        {
                            name: 'tipo_documento',
                            disabled: false,
                            type: 'select',
                            col: 12,
                            showTable: true,
                            options: genericOptions.tipo_documento,
                            validation: validationDefault(
                                ['required'],
                                'Tipo documento'
                            ),
                        },
                        {
                            name: 'numero_documento',
                            label: 'Número documento',
                            disabled: false,
                            type: 'integer',
                            col: 12,
                            showTable: true,
                            validation: validationDefaultNew(
                                [
                                    { type: 'required', value: true },
                                    { type: 'maxLength', value: 14 },
                                    { type: 'minLength', value: 4 },
                                ],
                                'número de documento'
                            ),
                        },
                        {
                            name: 'numero_producto',
                            label: 'Número cuenta',
                            disabled: false,
                            type: 'integer',
                            col: 12,
                            showTable: true,
                            validation: validationDefaultNew(
                                [
                                    { type: 'required', value: true },
                                    { type: 'maxLength', value: 12 },
                                    { type: 'minLength', value: 8 },
                                ],
                                'número de documento'
                            ),
                        },
                        {
                            name: 'numero_celular',
                            label: 'Celular',
                            disabled: false,
                            type: 'integer',
                            col: 12,
                            showTable: true,
                            validation: validationDefault(
                                ['required'],
                                'celular'
                            ),
                        },
                        {
                            name: 'token',
                            label: 'Token',
                            disabled: false,
                            type: 'string',
                            col: 12,
                            validation: validationDefaultNew(
                                [{ type: 'required', value: true }],
                                'Token'
                            ),
                        },
                        {
                            name: 'valor_operacion',
                            label: 'Valor operación',
                            disabled: false,
                            type: 'price',
                            col: 12,
                            validation: validationDefault(
                                ['required'],
                                'Valor operación'
                            ),
                        },
                        {
                            name: 'valor_efectivo',
                            label: 'Valor pago en efectivo',
                            disabled: false,
                            type: 'price',
                            col: 12,
                            validation: validationDefaultNew(
                                [{ type: 'required', value: true }],
                                'Valor pago en efectivo'
                            ),
                        },
                        {
                            name: 'valor_devuelta',
                            label: 'Valor devuelta',
                            disabled: true,
                            type: 'price',
                            col: 12,
                        },
                        {
                            name: 'corresponsal_bancario',
                            disabled: false,
                            type: 'hidden',
                            col: 12,
                            value: '',
                        },
                    ],
                },
            },
            formTypeProcedure: {
                permisions: [],
                tittle: 'tipo_tramite',
                fields: [
                    {
                        name: 'clasificacion_tramite',
                        disabled: false,
                        type: 'select',
                        col: 4,
                        showTable: true,
                        options: genericOptions.clasificacion_tramite,
                        validation: validationDefault(
                            ['required'],
                            'Clasificacion tramite'
                        ),
                    },
                    {
                        name: 'nombre',
                        label: 'Tipo tramite',
                        disabled: false,
                        type: 'string',
                        col: 4,
                        showTable: true,
                        validation: validationDefault(['required'], 'Nombre'),
                    },
                    {
                        name: 'valor',
                        disabled: false,
                        type: 'price',
                        col: 4,
                        validation: validationDefault(['required'], 'valor'),
                    },
                    {
                        name: 'costo',
                        label: 'Valor pse',
                        disabled: false,
                        type: 'price',
                        col: 4,
                        validation: validationDefault(
                            ['required'],
                            'Valor pse'
                        ),
                    },
                    {
                        name: 'valor_ganancia',
                        label: 'Ganacia',
                        disabled: false,
                        type: 'price',
                        col: 4,
                        validation: validationDefault(['required'], 'Ganacia'),
                    },
                    {
                        name: 'estado',
                        disabled: false,
                        type: 'select',
                        col: 4,
                        showTable: true,
                        options: genericOptions.estado,
                        validation: validationDefault(['required'], 'Estado'),
                    },
                ],
            },
            formProcessOnline: {
                permisions: [],
                tittle: 'tramite_en_linea',
                fields: [
                    {
                        name: 'punto_de_venta',
                        disabled: false,
                        type: 'select',
                        col: 5,
                        url: '/globals/users/?is_active=true',
                        notSearchGlobals: true,
                        validation: validationDefault(
                            ['required'],
                            'Punto de venta'
                        ),
                    },
                    {
                        name: 'usuario_genera_tramite',
                        disabled: false,
                        type: 'hidden',
                        col: 5,
                        value: '',
                    },
                ],
            },
            formProcessOnlineDetail: {
                permisions: [],
                tittle: 'tramite_en_linea',
                fields: [
                    {
                        name: 'tipo_tramite',
                        label: 'Tipo de tramite',
                        disabled: false,
                        type: 'select',
                        col: 5,
                        showTable: true,
                        options: [],
                        url: '/globals/tipo_tramite/?clasificacion_tramite=TRAMITE_EN_LINEA',
                    },
                    {
                        name: 'referencia',
                        disabled: false,
                        type: 'string',
                        col: 4,
                        showTable: true,
                    },
                ],
            },
            formSpecialProcedures: {
                permisions: [],
                tittle: 'tramite_en_linea',
                fields: [
                    {
                        name: 'punto_de_venta',
                        disabled: false,
                        type: 'select',
                        col: 3,
                        url: '/globals/users/?is_active=true',
                        notSearchGlobals: true,
                        validation: validationDefault(
                            ['required'],
                            'Punto de venta'
                        ),
                    },
                    {
                        name: 'estado',
                        disabled: false,
                        type: 'select',
                        col: 3,
                        showTable: true,
                        options: genericOptions.estado_tramite,
                        validation: validationDefault(['required'], 'Estado'),
                    },
                    {
                        name: 'observaciones',
                        disabled: false,
                        type: 'string',
                        col: 6,
                        showTable: true,
                    },
                    {
                        name: 'usuario_genera_tramite',
                        disabled: false,
                        type: 'hidden',
                        col: 5,
                        value: '',
                    },
                ],
            },
            formSpecialProceduresDetail: {
                permisions: [],
                tittle: 'tramite_especial',
                fields: [
                    {
                        name: 'tipo_tramite',
                        label: 'Tipo de tramite',
                        disabled: false,
                        type: 'select',
                        col: 4,
                        showTable: true,
                        options: [],
                        url: '/globals/tipo_tramite/?clasificacion_tramite=TRAMITE_ESPECIAL',
                    },
                    {
                        name: 'descripcion',
                        disabled: false,
                        type: 'string',
                        col: 4,
                        showTable: true,
                    },
                    {
                        name: 'cantidad',
                        disabled: false,
                        type: 'integer',
                        col: 4,
                        validation: validationDefault(
                            ['required'],
                            'Cantidad '
                        ),
                    },
                    {
                        name: 'valor',
                        disabled: false,
                        type: 'price',
                        col: 4,
                        validation: validationDefault(['required'], 'Valor'),
                    },
                    {
                        name: 'costo',
                        disabled: false,
                        type: 'price',
                        col: 4,
                        validation: validationDefault(['required'], 'Costo'),
                    },
                    {
                        name: 'valor_ganancia',
                        label: 'Ganancia',
                        disabled: false,
                        type: 'price',
                        col: 4,
                        validation: validationDefault(['required'], 'Ganancia'),
                    },
                ],
            },
            formParameterization: {
                permisions: [],
                tittle: 'tipo_tramite',
                fields: [
                    {
                        name: 'anio',
                        label: 'Año',
                        disabled: false,
                        type: 'select',
                        col: 4,
                        showTable: true,
                        options: genericOptions.anio,
                        validation: validationDefault(['required'], 'Año'),
                    },
                    {
                        name: 'mes',
                        label: 'Mes',
                        disabled: false,
                        type: 'select',
                        col: 4,
                        showTable: true,
                        options: genericOptions.mes,
                        validation: validationDefault(['required'], 'Mes'),
                    },
                    {
                        name: 'semana',
                        label: 'Corte',
                        disabled: false,
                        type: 'select',
                        col: 4,
                        showTable: true,
                        options: genericOptions.semana,
                        validation: validationDefault(['required'], 'Semana'),
                    },
                    {
                        name: 'fecha_desde',
                        label: 'Fecha desde',
                        disabled: false,
                        type: 'date',
                        col: 4,
                        validation: {
                            required: {
                                value: true,
                            },
                        },
                    },
                    {
                        name: 'fecha_hasta',
                        label: 'Fecha hasta',
                        disabled: false,
                        type: 'date',
                        col: 4,
                        validation: {
                            required: {
                                value: true,
                                message: 'Fecha desde',
                            },
                        },
                    },
                    {
                        name: 'estado_cierre_contable',
                        label: 'Estado',
                        disabled: false,
                        type: 'select',
                        col: 4,
                        showTable: true,
                        options: genericOptions.estado_cierre,
                        validation: validationDefault(['required'], 'Estado'),
                    },
                    {
                        name: 'usuario_cierre',
                        disabled: false,
                        type: 'hidden',
                        col: 2,
                        value: '',
                    },
                ],
            },
            formSalesInvoiceParameters: {
                permisions: [],
                tittle: 'formato_venta',
                general: {
                    fields: [
                        {
                            name: 'celular',
                            label: 'Telefóno soporte',
                            disabled: false,
                            type: 'celular',
                            col: 3,
                            validation: validationDefaultNew(
                                [{ type: 'required', value: true }],
                                'Número telefónico'
                            ),
                        },
                    ],
                },
                lunes_sabado: {
                    fields: [
                        {
                            name: 'hora_desde_lunes_sabado',
                            label: 'Hora desde',
                            disabled: false,
                            type: 'hour',
                            col: 3,
                            validation: {
                                required: {
                                    value: true,
                                },
                            },
                        },
                        {
                            name: 'hora_hasta_lunes_sabado',
                            label: 'Hora hasta',
                            disabled: false,
                            type: 'hour',
                            col: 3,
                            validation: {
                                required: {
                                    value: true,
                                },
                            },
                        },
                    ],
                },
                domingos_festivos: {
                    fields: [
                        {
                            name: 'hora_desde_domingos_festivos',
                            label: 'Hora desde',
                            disabled: false,
                            type: 'hour',
                            col: 3,
                            validation: {
                                required: {
                                    value: true,
                                },
                            },
                        },
                        {
                            name: 'hora_hasta_domingos_festivos',
                            label: 'Hora hasta',
                            disabled: false,
                            type: 'hour',
                            col: 3,
                            validation: {
                                required: {
                                    value: true,
                                },
                            },
                        },
                    ],
                },
            },
        },
    },
};

export default Rules;
